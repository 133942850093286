.popular-posts__section{
    /* background-color: rgba(206, 206, 206, 0.74); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.background{
    background-color: rgba(206, 206, 206, 0.74);
}

.popular-posts_title{
    color: black;
    font-size: x-large;
    display: block;
    max-width: 1400px;
    padding-bottom: 30px;
}

.horizontal-divider{
    padding-top: 20px;
    width: 80%;
}

.carousel{
    width: 100%;
}

.carousel-item{
    position: relative;
}

.small-title{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 1rem;
    color: white;
    text-align: center;
    z-index: 5;

    @media screen and (max-width: 370px){
        font-size: large;
    }

    @media screen and (max-width: 300px){
        font-size: medium;
    }
}

.gray-backdrop {
    text-shadow: 0px 0px 8px black;
    padding: 12px;
    background-color: var(--secondary-color-a);
    border-radius: var(--form-corners);
    box-shadow: 0 0 6px 3px var(--secondary-color-a); /* Adjust the alpha value for the shadow */
}

.slide{
    min-height: 250px;
    img{
        min-height: 250px;
        object-fit: cover;
    }

}

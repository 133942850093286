.navbar-logo{
    height: 75px;
    width: auto;
    margin-right: 1rem;

    @media screen and (max-width: 501px) and (min-width: 292px){
        display: none;
    }

    @media screen and (max-width: 292px){
        height: 40px;
    }
}

.navbar-logo-compressed{
    height: 40px;
    width: auto;

    @media screen and (min-width: 501px){
        display: none;
    }

    @media screen and (max-width: 292px){
        display: none;
    }
}

/* @media (max-width: 500px) {
    .navbar-expand-lg .navbar-collapse {
      flex-basis: auto;
    }
  } */
  
  @media (min-width: 501px) {
    .navbar-toggler {
        display: none !important; 
      }
    .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .navbar-nav {
      flex-direction: row;
    }
    .navbar-nav .nav-item {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
  }



  .nav-link{
    color: white;
    font-size: large;
  }

.nav-link.active, .nav-link:hover, .nav-link:focus {
  color: var(--tertiary-color);
}

.menu-box{
    display: flex;
    flex-direction: column;
}

.yellow-link{
    color: var(--tertiary-color);
}

.navbar-subcategory-ham{
    display: none;
    padding-left: 32px
}

@media screen and (max-width: 500px){
    .navbar-subcategory{
        display: none;
    }

    .navbar-subcategory-ham{
        display: inline-block;
    }


}

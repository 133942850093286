.contact__section{
    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--secondary-color-a);
    

    --max-width: 800px;
}

.top_padding-100{
    padding-top: 100px;
}

.contact_header{
    margin-top: 30px;
    font-size: 3rem;
    margin-bottom: 10px;
    color: white;
    text-align: center;
}
.contact-form{
    display: flex;
    flex-direction: column;
    max-width: var(--max-width);
    width: 100%;
    padding: 0px 8px;
    font-size: large;
}

.contact-form_inputs{
    border-radius: var(--form-corners);
    padding: 6px 12px;
    outline: none;
    border: none;
    margin: 20px 0px;
    background-color: var(white);
    box-shadow: 0px 0px 4px black;

    transition: all 0.2s ease-in-out;

}

.contact-form_inputs:hover{
    box-shadow: 0px 0px 12px black;
}

.contact-form_inputs:active{
    box-shadow: 0px 0px 12px black;
}

.message_text-area{
    height: 20rem;
}

.contact-form_submit{
    border-radius: var(--form-corners);
    background-color: var(--tertiary-color);
    color: var(--secondary-color);
    padding: 6px 12px;
    margin: 20px 0px;
    width: 100%;
    border: none;
    outline: none;
    font-size: large;
    font-weight: bold;

    transition: all 0.2s ease-in-out;
}

.contact-form_submit:hover{
    background-color: var(--tertiary-color-hover);
    box-shadow: 0px 0px 8px black;
}

.contact-form_submit:active{
background-color: var(--tertiary-color-active);
box-shadow: 0px 0px 12px black;
}

.newsletter_section{
    width: 100%;
}

.form-error{
    color: rgb(255, 0, 0);
    font-weight: bold;
    margin: 0px;
}

.form-success-box{
    height: 676px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.form-success{
    background-color: rgba(255, 255, 255, 0.76);
    border-radius: 50px;
    color: green;
    font-weight: bold;
    font-size: x-large;
    padding: 8px 16px;
    margin: 0px;
    text-align: center;
}
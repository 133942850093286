.newsletter__section{
    background-color: var(--secondary-color-a);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;

    --subscribe-height: 84px;

    @media screen and (max-width: 450px){
        --subscribe-height: 116px;
    }
}

.newsletter-title{
    color: var(--primary-color);
    text-align: center;
    padding: 16px;

}

.newsletter-subtitle{
    color: white;
    padding: 16px;
    text-align: center;
}

.divider{
    width: 80%;
    height: 1px;
    margin: 20px;
    background-color: white;
}

.newsletter-input-button-box{
    padding: 20px 0px;
    margin-bottom: 20px;
    width: 80%;
    height: var(--subscribe-height);
    display: flex;
    justify-content: center;
    align-items: center;
    

    @media screen and (max-width: 450px){
        flex-direction: column;
    }
}

.newsletter-input {
    width: 300px; 
    padding: 8px 16px;
    background-color: var(--secondary-color);
    color: white;
    margin-right: 10px; 
    border-radius: 50px;
    border: 2px solid white;
    outline: none;

    @media screen and (max-width: 450px){
        margin: 32px;
    }

    @media screen and (max-width: 320px){
        width: 100%;
    }
}

  
.newsletter-button {
    padding: 8px 16px;
    background-color: var(--tertiary-color);
    color: var(--secondary-color);
    border: none;
    cursor: pointer;
    border-radius: 50px;
    border: none;
    font-weight: bold;

    transition: all .2s ease-in-out;
}

.newsletter-button:hover{
    background-color: var(--tertiary-color-hover);
    box-shadow: 0px 0px 8px black;
}

.newsletter-button:active{
    background-color: var(--tertiary-color-active);
    box-shadow: 0px 0px 12px black;
}

.error-message{
    color: rgb(255, 0, 0);
    font-weight: bold;
}

.success-message-box{
    height: var(--subscribe-height);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.success-message{
    color: green;
    font-weight: bold;
    font-size: large;
    padding: 8px;
    margin: 0px;
    text-align: center;
}
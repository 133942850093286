.latest-posts__section{
    background-color: var(--light-background);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}

.latest-posts_title{
    color: black;
    font-size: x-large;
    display: block;
    max-width: 1400px;
    text-align: center;
}

.latest-posts__articles{
    max-width: 1400px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;

    @media screen and (max-width: 631px){
        justify-content: center;
    }
}

.latest-posts__article-wrapper{
    max-width: 300px;
    margin: 8px;
}

.show-more{
    color: blue;
    cursor: pointer;
}


.topPad{
    
    padding-top: 140px;

    @media screen and (max-width: 500px){
        padding-top: 100px;
    }
}
:root{
    --primary-color: #ffffff;
    --secondary-color: #2b3035;
    /* --secondary-color: #3a3a3a; */
    --tertiary-color: #efd827;
    --tertiary-color-hover: #d1bd22;
    --tertiary-color-active: #b6a41e;

    --light-background: rgba(206, 206, 206, 0.74);

    --secondary-color-a: #2b3035bd;

    --form-corners: 12px;
    --corners: 25px;

    --header-height: 130px;
    --header-height-collapsed: 100px;

    --navbar-collapse: 500px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

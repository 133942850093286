.faq__section{
    background-color: var(--secondary-color);

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 6;
    width: 100%;
}

.faq-accordion{
    max-width: 1400px;
    width: 100%;

}

.accordion-item{
    width: 100%;
}

.dark-mode{
    background-color: var(--secondary-color);
    color: white;
    border: none;
    outline: none;
}

.dark-mode-header{
    background-color: var(--secondary-color);
    color: var(--tertiary-color);
    fill: var(--tertiary-color);
    
    border: none;
    outline: none;
}

.dark-mode-header::after {
    color: var(--tertiary-color) !important;
    filter: invert(74%) sepia(60%) saturate(552%) hue-rotate(7deg) brightness(105%) contrast(95%);
}


/* Styling the selected accordion header */
.accordion-button[aria-expanded="true"] {
    background-color: var(--secondary-color);
    color: var(--tertiary-color);
    /* Add any other desired styles */
}

/* Optional: If you want to add a border or shadow to the selected header */
.accordion-button[aria-expanded="true"] {
    border: 2px solid var(--tertiary-color);
    box-shadow: 0 0 2px 2px var(--tertiary-color);
}
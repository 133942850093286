.about__section{
    background-color: var(--light-background);
    padding: 12px;
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* overflow-x: hidden; */

    --single-column-break: 850px;
}

.about__section__what-we-do{
    flex-direction: column;
}

.who-we-are_box{
    display: flex;
    flex-direction: column;
    max-width: 1000px;
}

.about__header{
    text-align: center;
    margin: 16px 0px;
}

.about__dr-box{
    background-color: var(--secondary-color-a);
    padding: 32px;
    margin: 16px 0px 32px 0px;
    border-radius: var(--corners);
    color: white;

    display: flex;

    @media screen and (max-width: 850px){
        flex-direction: column;
    }

    @media screen and (max-width: 500px){
        padding: 0px;
    }
}

.dr-name-pic{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding-right: 32px;
    border-right: 2px solid;


    img{
        border-radius: 50%;
        vertical-align: center;
    }

    @media screen and (max-width: 850px){
        border-right: none;
        border-bottom: 2px solid white;
        align-items: center;
        padding: 16px;
        
        img{
            width: 80vw;
            height: 80vw;
            padding: 32px;
        }
    }

}

.contact-info{
    line-height: .5rem;
}

.dr-bio{
    padding-left: 32px;
    font-size: large;

    @media screen and (max-width: 850px){
        padding: 16px;
    }
}

.what-we-do__box{
    display: flex;
    flex-direction: column;
    
    max-width: 1000px;
}

.topPad{
    
    padding-top: 120px;

    @media screen and (max-width: 500px){
        padding-top: 80px;
    }
}
.main-posts__section{
    
    background-color: var(--secondary-color-a);
    padding: var(--header-height-collapsed) 8px 32px 8px;
    /* padding-top: var(--header-height-collapsed); */

    display: flex;
    justify-content: center;
    align-self: center;

    @media screen and (max-width: 1149px){
        padding: var(--header-height-collapsed) 2px 16px 2px;
    }

    @media screen and (min-width: 500px){
        padding-top: var(--header-height);
    }

}

.main-posts__articles{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1400px;
}

.main-post__side-articles{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 12px;
}

.full{
    @media screen and (max-width: 1150px){
        display: none;
    }
}

.mid{

    display: none;
    flex-direction: column;

    @media screen and (min-width: 650px) and (max-width: 1149px){
        display: flex;
    }
}

.mid-inner{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.small{
    display: none;
    flex-direction: column;

    @media screen and (max-width: 649px){
        display: flex;
    }
}

.article-small{
    margin: 6px 0px;
}

.article1-full{
    margin-right: 8px;
}

.footer__section{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    background-color: var(--secondary-color);

}

.footer-logo-long{
    max-width: 25%;
    margin: 20px 10px;
}

.footer-text{
    align-items: center;
    justify-content: center;
    margin: 20px 0px;

    @media screen and (max-width: 350px){
        display: flex;
        flex-direction: column;
    }

}

.footer-copyright-text{
    color: white;
    font-size: large;
}

.footer-ript-text{
    color: var(--tertiary-color);
    font-size: large;
}

.footer-social-icons{
    margin: 20px 0px;
    display: flex;
}

@media screen and (max-width: 1100px){
    .footer-logo-long{
        max-width: 35%;
    }
    .footer__section{
        justify-content: space-around;
    }
}


@media screen and (max-width: 800px){
    .footer-logo-long{
        max-width: 50%;
    }
}

@media screen and (max-width: 600px){
    .footer-logo-long{
        max-width: 70%;
    }

    .footer-copyright-text, .footer-ript-text{
        font-size: medium;
    }
}

@media screen and (max-width: 350){
    .footer-logo-long{
        max-width: 80%;
    }

    .footer-copyright-text, .footer-ript-text{
        font-size: smaller;
    }
}




/* Social Media Icon Stylings */
.social-wrapper{
    --max-size: 60px;

    max-width: var(--max-size);
    max-height: var(--max-size);
    position: relative;
    margin: 10px;
}

.social-wrapper:hover{
    .white-background{
        background-color: rgb(129, 129, 129);
    }
}

.social-icon{
    max-width: 100%;
    z-index: 1;
    position: relative;
}

.white-background{
    position: absolute;
    top: 2px;
    left: 2px;
    height: 94%;
    width: 94%;
    border-radius: 50%;
    z-index: 0;
    transition: all 0.3s ease-in-out;
}


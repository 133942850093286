:root{
    --main-article-width: 100%;
    --main-img-height: 550px;

    @media screen and (min-width: 650px) and (max-width: 1149px){
        --main-img-height: 350px;
    }
}

.article-card{
    color: white;
    border-radius: var(--corners);

    display: flex;
    flex-direction: column;
    width: var(--main-article-width);
    /* border: 2px solid white; */
    /* margin: 6px 6px; */


    transition: all 0.15s ease-in-out;

    @media screen and (min-width: 650px) and (max-width: 1149px){
        width: 46vw;
        padding: 8px;
    }
}

.article-card:hover{
    cursor: pointer;
    box-shadow: 0px 0px 8px black;
}

.article-card_image-box{
    height: var(--main-img-height);
    width: var(--main-article-width);

}

.article-card_image{
    border-top-right-radius: var(--corners);
    border-top-left-radius: var(--corners);
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card-body{
    margin: 24px;
}

.article-card_date{
    font-size: small;
    font-weight: 400;
}

.article-card_title{
    font-size: x-large;
    height: 3.6rem;
}

.article-card_text{
    font-size: large;
}


.blog-post__section{
    background-color: var(--light-background);
    /* margin-top: var(--header-height); */
    padding: 32px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 650px) {
        padding: 16px;
    }

    @media screen and (max-width: 450px) {
        padding: 0px;
    }

}

.blog-post_padding{
    padding: 32px;

    @media screen and (max-width: 650px) {
        padding: 16px;
    }

    @media screen and (max-width: 450px) {
        padding: 12px;
    }

}

.margin-x{
    margin: 0px 12px;
}

.blog-post_box{
    max-width: 1000px;
    padding: 32px;
    background-color: white;
    /* box-shadow: 0px 0px 16px 16px white; */
    border-radius: var(--corners);

    display: flex;
    flex-direction: column;

    @media screen and (max-width: 650px) {
        padding: 16px;
    }

    @media screen and (max-width: 450px) {
        padding: 16px 0px;
        border-radius: 0px;
    }
}

.blog-post_title{
    margin-top: 16px;
}

.blog-post_date{
    margin-bottom: 16px;
}

.post_content{
    font-size: large;
}

.blog-post_cover{
    margin-bottom: 32px;
    width: 100%;
    min-height: 300px;
    object-fit: cover;

    @media screen and (max-width: 500px){
        min-height: 300px;
        object-fit: cover;
    }
    @media screen and (max-width: 300px){
        min-height: 250px;
        object-fit: cover;
    }
}

.blog-post_author{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    
    img{
        border-radius: 50%;
        height: 4rem;
        margin: 16px 16px 16px 0px;
    }

}

.blog-post_divider{
    margin: 32px 0px;
    width: 100%;
}


/* Author Bio CSS */
.author-bio__section{
    display: flex;
    max-width: 1000px;
    

    img{
        height: 200px;
        width: 200px;
        border-radius: var(--corners);
    }

    p{
        margin-left: 32px;
        font-size: large;
    }

    @media screen and (max-width: 750px){
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img{
            height: 60vw;
            width: auto;
            border-radius: var(--corners);
        }

        p{
            margin-top: 32px;
            margin-left: 0px;
            font-size: large;
        }
    }
}


.topPad{
    
    padding-top: 140px;

    @media screen and (max-width: 500px){
        padding-top: 80px;
    }

    @media screen and (max-width: 450px){
        padding-top: 60px;
    }
}
.landing__page{
    padding-top: 100px;
    max-width: 100vw;
    /* width: 100vw; */
    padding-bottom: 64px;
    background-color: var(--secondary-color-a);
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    /* overflow-x: hidden; */
}

.landing-hero{
    max-width: 1400px;
    position: relative;
    display: flex;
    
    align-items: center;
    z-index: 2;

    @media screen and (max-width: 1000px){
        flex-direction: column;

        span{
            padding: 0px 12px;
            text-align: center;
            font-size: 3rem;
        }
    }

    @media screen and (max-width: 330px){
        span{
            padding: 0px 6px;
            text-align: center;
            font-size: 2.5rem;
        }
    }

}

.hero-box{
    margin: 64px;
    display: flex;
    position: relative;
    width: 25vw;
    @media screen and (max-width: 1000px){
        width: 60vw;
        margin: 0px;
    }
    @media screen and (max-width: 400px){
        width: 80vw;
    }
}

.landing-hero-logo{
    position: absolute;
    bottom: 30px;
    width: 30vw;
    @media screen and (max-width: 1000px){
        width: 70vw;
    }
}

.landing-hero-hero{
    
    border-radius: 50%;
    box-shadow: 0px 0px 16px 8px rgba(0, 0, 0, 0.685);
    width: 25vw;

    @media screen and (max-width: 1000px){
        width: 60vw;
    }

    
    @media screen and (max-width: 400px){
        width: 80vw;
    }
    
}


.hero-text{
    color: white;
    font-size: 5rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.hero-text-john{
    color: var(--tertiary-color);
    text-shadow: 0px 0px 8px white;
    font-weight: 700;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.hero-text-john:hover{
    color: var(--tertiary-color-hover);
    text-shadow: 0px 0px 10px white;
}

.hero-text-john:active{
    color: var(--tertiary-color-active);
    text-shadow: 0px 0px 12px white;
}

.hero-link-cta{
    text-decoration: none;
    display: flex;
    align-items: center;
}

.come-to-you{
    padding-top: 100px;
    width: 100%;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
}

.client-therapy-image{
    width: 100%;
    height: auto;
    z-index: 1;
    border-bottom: 50px solid var(--secondary-color);
}

.client-therapy-image-logo{
    width: 80vw;
    height: auto;
    position: absolute;
    top: 40%;
    z-index: 0;

    @media screen and (max-width: 550px){
        top: 50%;
        width: 90vw;
    }
    @media screen and (max-width: 300px){
        top: 60%;
    }
}

.landing-rethink{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: relative;

    @media screen and (max-width: 1000px){
        flex-direction: column;

        span{
            padding: 0px 12px;
            text-align: center;
            font-size: 3rem;
        }
    }

    @media screen and (max-width: 330px){
        span{
            padding: 0px 6px;
            text-align: center;
            font-size: 2.5rem;
        }
    }
}

.landing-text{
    text-align: center;
    z-index: 2;
}

.landing-rethink-button{
    font-size: 2rem;
    padding: 16px;
    background-color: var(--tertiary-color);
    color: var(--secondary-color);
    border-radius: var(--corners);
    margin: 16px;
    box-shadow: 0px 0px 8px black;
    cursor: pointer;
    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: all 0.2s ease-in-out;

    span{
        text-align: center;
    }

    @media screen and (max-width: 1000px){
        margin-top: 64px;
    }
}


.landing-rethink-button:hover{
    background-color: var(--tertiary-color-hover);
    box-shadow: 0px 0px 10px black;
}

.landing-rethink-button:active {
    background-color: var(--tertiary-color-active);
    box-shadow: 0px 0px 12px black;
    z-index: 4;
}

.rethink-link-cta{
    text-decoration: none;
    z-index: 2;
}

.landing-contact{
    background-color: var(--secondary-color-a);
    margin: 0px;
    color: white;
    text-align: center;
    padding: 64px;
}

.blog-link{
    text-decoration: none;
}

.landing-gray-background-top{
    background-color: var(--secondary-color);
    width: 100%;
    height: 600px;
    transform: skewY(15deg);
    position: absolute;
    top: -100px;
    right: 0px;
}

.landing-gray-background-mid{
    background-color: var(--secondary-color);
    width: 100%;
    height: 100px;
    transform: skewY(15deg);
    position: absolute;
    top: 20%;
    right: 0px;
    z-index: 0;

}

.landing-gray-background-mid2{
    background-color: var(--secondary-color);
    width: 100%;
    height: 300px;
    transform: skewY(15deg);
    position: absolute;
    bottom: 0;
    right: 0px;
    left: 0px;
    z-index: 0;

    @media screen and (max-width: 320px){
        bottom: -100px;
    }

    /* @media screen and (max-width: 2000px) {
        top: 550px;
    }

    @media screen and (max-width: 2000px) {
        top: 550px;
    } */

    @media screen and (max-width: 1000px){
        height: 200px;
    }
/*
    @media screen and (max-width: 1250px){
        top: 0px;
    } */
}

.navbar{
    background-color: var(--secondary-color);
}

.landing-rethink{
    padding-top: 100px;
}

.landing-rethink-tennis-training{
    width: 0vw;
    margin: 12px;
    border-radius: 50%;
    z-index: 20;

    @media screen and (max-width: 1000px){
        margin-top: 64px;
        width: 60vw;
    }

    @media screen and (max-width: 1000px){
        margin-top: 64px;
        width: 80vw;
    }


}

.faq-box{
    
    
    width: 100%;


    /* @media screen and (min-width: 1001px) {
        position: relative;
        bottom: -100px;
    }

    @media screen and (max-width: 1000px) {
        bottom: 8%;
    }
    @media screen and (max-width: 800px) {
        bottom: 6%;
    }
    @media screen and (max-width: 550px) {
        bottom: 4%;
    }
    @media screen and (max-width: 500px) {
        bottom: 0%;
    }
    @media screen and (max-width: 400px) {
        bottom: -2%;
    }
    @media screen and (max-width: 360px) {
        bottom: -6%;
    } */

    margin-top: 30px;
    /* margin-bottom: -100px; */
}


:root{
    --side-article-width: 100%;
    --side-article-height: 250px;
    --side-img-width: 19vw;

}

.article-card-horizontal{
    color: white;
    border-radius: var(--corners);

    display: flex;
    flex-direction: row;
    width: var(--side-article-width);
    height: var(--side-article-height);

    transition: all 0.15s ease-in-out;
}

.article-card-horizontal:hover{
    cursor: pointer;
    box-shadow: 0px 0px 8px black;
}

.article-card-horizontal_image-box{
    height: var(--side-img-height);
    width: var(--side-article-width);
    position: relative;
    max-width: 60%;

}

.article-card-horizontal_image{
    border-bottom-left-radius: var(--corners);
    border-top-left-radius: var(--corners);
    width: 300px;
    height: 100%;
    object-fit: cover;
}

.card-body-horizontal{
    margin: 24px;
}

.article-card-horizontal_date{
    font-size: small;
    font-weight: 400;
}

.article-card-horizontal_title{
    font-size: large;
}

.article-card-horizontal_text{
    font-size: medium;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Limit to two lines */
    -webkit-box-orient: vertical; /* Ensure vertical layout */
    overflow: hidden; /* Hide overflowing text */
    text-overflow: ellipsis; /* Display ellipsis for overflowed text */
}

.category-tag{
    position: absolute;
    left: 10px;
    bottom: 10px;
}

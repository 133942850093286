
.article-card-fixed{
    color: black;
    border-radius: var(--corners);

    display: flex;
    flex-direction: column;
    width: var(--main-article-width);
    /* border: 2px solid white; */
    /* margin: 10px; */
    margin: 6px 0px;

    --main-article-width: 100%;

    transition: all 0.15s ease-in-out;
}

.article-card-fixed:hover{
    cursor: pointer;
    box-shadow: 0px 0px 8px black;
}

.article-card-fixed_image-box{
    height: 250px;
    width: var(--main-article-width);

}

.article-card-fixed_image{
    border-top-right-radius: var(--corners);
    border-top-left-radius: var(--corners);
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card-body-fixed{
    margin: 24px;
}

.article-card-fixed_date{
    font-size: small;
    font-weight: 400;
}

.article-card-fixed_title{
    font-size: large;
    height: 3.6rem;
}

.article-card-fixed_text{
    font-size: medium;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Limit to two lines */
    -webkit-box-orient: vertical; /* Ensure vertical layout */
    overflow: hidden; /* Hide overflowing text */
    text-overflow: ellipsis; /* Display ellipsis for overflowed text */
}